import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { ExceptionApi, ExceptionTypes } from '@frontend/api-schedule-exceptions';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../../../context/AppointmentsCalendarViewContext';
import { useCalendarEventsConfigurationShallowStore } from '../../../../hooks';
import { shouldInvalidateExceptions } from '../../../../utils';
import { CalendarEventsEnums } from '../../types';
import { getExceptionReqObject } from '../../utils';
import { OutOfOfficeEventsForm } from './OutofOfficeEventForm';
import { ExceptionFormValues, OnDeleteExceptionParams } from './types';

type OutOfOfficeEventFormContainerProps = {
  eventType: CalendarEventsEnums;
  closeModal: () => void;
};

export const OutOfOfficeEventFormContainer = ({ eventType, closeModal }: OutOfOfficeEventFormContainerProps) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const queryClient = useQueryClient();

  const [formError, setFormError] = useState<string>('');
  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);

  const { providersList, selectedLocationIds, selectedDate } = useAppointmentsCalendarViewProps();
  const { configuredCalendarEvent } = useCalendarEventsConfigurationShallowStore('configuredCalendarEvent');

  // Handles the deletion of an exception event for V2
  const handleDeleteExceptionV2 = async ({ eventId, locationId, providerId }: OnDeleteExceptionParams) => {
    setIsFormSaving(true);
    try {
      await ExceptionApi.deleteException(locationId ?? '', eventId ?? '', providerId ?? '');
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => shouldInvalidateExceptions(queryKey, locationId, providerId),
      });
      alert.success(t('Successfully deleted exception'));
      closeModal();
    } catch (error: any) {
      console.error(error);
      if (error) {
        setFormError('Something went wrong while deleting the exception');
        alert.error(t('Something went wrong while deleting the exception'));
      }
    } finally {
      setIsFormSaving(false);
    }
  };

  // Handles the saving of an exception event for V2
  const handleOnSaveExceptionV2 = async (formValues: ExceptionFormValues) => {
    const reqObj = getExceptionReqObject({
      isAvailable: false,
      name: formValues.name,
      endDate: formValues.endDate ?? '',
      endTime: formValues.endTime ?? '',
      startDate: formValues.startDate ?? '',
      startTime: formValues.startTime ?? '',
      id: configuredCalendarEvent?.eventId ?? '',
    });

    try {
      const providerId =
        formValues.eventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT ? formValues.providerId : '';
      await manageFormSubmit(providerId, formValues.locationId, reqObj);
    } catch (error: any) {
      console.error(error);
      if (error) {
        setFormError('Something went wrong, please check the form is filled in correctly');
      }
    }
  };

  // Handles the saving of an exception event for V2
  const manageFormSubmit = async (providerId: string, locationId: string, reqObj: ExceptionTypes.Exception) => {
    if (providerId && locationId) {
      await ExceptionApi.createProviderException(reqObj, locationId, providerId || '');
    } else {
      await ExceptionApi.createException(reqObj, locationId);
    }

    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => shouldInvalidateExceptions(queryKey, locationId, providerId),
    });

    alert.success(t('Successfully created exception'));
    closeModal();
  };

  const handleOnDelete = ({ eventId, locationId, providerId, eventType }: OnDeleteExceptionParams) => {
    if (eventId) {
      handleDeleteExceptionV2({ eventId, locationId, providerId, eventType });
    }
  };

  const handleOnSave = (formValues: ExceptionFormValues) => {
    handleOnSaveExceptionV2(formValues);
  };

  return (
    <OutOfOfficeEventsForm
      closeModal={closeModal}
      eventType={eventType}
      disableEventType={!!configuredCalendarEvent?.eventId}
      providerList={providersList}
      selectedLocationIds={selectedLocationIds}
      selectedDate={selectedDate as string}
      isLoading={isFormSaving}
      formError={formError}
      onDelete={handleOnDelete}
      onSave={handleOnSave}
    />
  );
};
