import { createJSONStorage } from 'zustand/middleware';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

enum ItemState {
  REGISTERED = 'REGISTERED',
  DEPRIORITIZATION_PENDING = 'DEPRIORITIZATION_PENDING',
}

type PriorityRendererItemData = {
  name: string;
  state: ItemState;
};

interface PriorityRendererGroup {
  name: string;
  priorityMap: Record<string, number>;
  items: PriorityRendererItemData[];
}

export interface PriorityRendererStore {
  groupsInitializedMap?: Record<string, boolean>;
  groups: Record<string, PriorityRendererGroup>;
  initializeGroup?: (data: { groupName: string; priorityList: string[] }) => void;
  reInitializeGroup?: (data: { groupName: string; priorityList: string[] }) => void;
  getGroup?: (groupName: string) => PriorityRendererGroup | undefined;
  registerItem?: (data: { groupName: string; itemName: string }) => void;
  markForDeprioritization?: (data: { groupName: string; itemName: string }) => void;
  unregisterItem?: (data: { groupName: string; itemName: string }) => void;
  isTopPriorityItem?: (data: { groupName: string; itemName: string }) => boolean;
  isGroupInitialized?: (groupName: string) => boolean;
}

export const usePriorityRendererStore = createStoreWithPersistAndSubscribe<PriorityRendererStore>(
  (set, get) => ({
    groupsInitializedMap: {},
    groups: {},

    reInitializeGroup: ({ groupName, priorityList }) => {
      const configPriorityMap = priorityList.reduce((acc, key, index) => {
        acc[key] = index;
        return acc;
      }, {} as Record<string, number>);

      set((state) => ({
        groupsInitializedMap: {
          ...state.groupsInitializedMap,
          [groupName]: true,
        },
        groups: {
          ...state.groups,
          [groupName]: {
            name: groupName,
            configPriorityMap,
            items: [],
          },
        },
      }));
    },

    initializeGroup: ({ groupName, priorityList }) => {
      if (get().groupsInitializedMap?.[groupName]) {
        console.log(`Group ${groupName} is already initialized`);
        return;
      }

      const group = get().groups[groupName];
      let priorityMap: Record<string, number> = {};

      const configPriorityMap = priorityList.reduce((acc, key, index) => {
        acc[key] = index;
        return acc;
      }, {} as Record<string, number>);

      if (group && Object.keys(group.priorityMap).length) {
        const deprioritizedItems = (group?.items || [])
          .filter((item) => item.state === ItemState.DEPRIORITIZATION_PENDING)
          .map((item) => item.name);

        // Cleaning up deprioritized items from priorityMap
        priorityMap = Object.fromEntries(
          Object.entries(group.priorityMap).filter(([key]) => !deprioritizedItems.includes(key))
        );
      }

      // If priority list is empty or all items are deprioritized then reset the priorityMap
      if (!Object.keys(priorityMap).length) {
        priorityMap = configPriorityMap;
      }

      set((state) => ({
        groupsInitializedMap: {
          ...state.groupsInitializedMap,
          [groupName]: true,
        },
        groups: {
          ...state.groups,
          [groupName]: {
            name: groupName,
            priorityMap,
            items: [],
          },
        },
      }));
    },

    getGroup: (groupName) => {
      if (!get().isGroupInitialized?.(groupName)) {
        return;
      }
      const group = get().groups[groupName];
      if (!group) {
        console.error(`Group with name ${groupName} not found`);
        return;
      }
      return group;
    },

    isGroupInitialized: (groupName) => !!get().groupsInitializedMap?.[groupName],

    isTopPriorityItem: ({ groupName, itemName }) => {
      return get().getGroup?.(groupName)?.items[0]?.name === itemName;
    },

    registerItem: ({ groupName, itemName }) => {
      const group = get().getGroup?.(groupName);
      if (!group) {
        return;
      }

      const isItemRegistered = group.items.find((item) => item.name === itemName);

      if (isItemRegistered) {
        return;
      }

      const items = [...group.items];
      const itemPriority = group.priorityMap[itemName];
      const newItem: PriorityRendererItemData = {
        name: itemName,
        state: ItemState.REGISTERED,
      };

      const insertIndex = items.findIndex((item) => {
        return group.priorityMap[item.name] === undefined || group.priorityMap[item.name] > itemPriority;
      });

      items.splice(insertIndex === -1 ? items.length : insertIndex, 0, newItem);

      set((state) => ({
        ...state,
        groups: {
          ...state.groups,
          [groupName]: {
            ...state.groups[groupName],
            items,
          },
        },
      }));
    },

    markForDeprioritization: ({ groupName, itemName }) => {
      const group = get().getGroup?.(groupName);
      if (!group) {
        return;
      }

      const groupItems = [...group.items];
      const matchedItem = groupItems.find((item) => item.name === itemName);

      if (!matchedItem) {
        console.error(`Item with name ${itemName} not found in group ${groupName}`);
        return;
      }
      matchedItem.state = ItemState.DEPRIORITIZATION_PENDING;

      set((state) => ({
        ...state,
        groups: {
          ...state.groups,
          [groupName]: {
            ...state.groups[groupName],
            items: groupItems,
          },
        },
      }));
    },

    unregisterItem: ({ groupName, itemName }) => {
      const group = get().getGroup?.(groupName);
      if (!group) {
        return;
      }

      // Remove registered item but ignore deprioritization pending items
      // Deprioritization pending items will be removed on next initialization
      const groupItems = group.items.filter((item) => !(item.name === itemName && item.state === ItemState.REGISTERED));
      set((state) => ({
        ...state,
        groups: {
          ...state.groups,
          [groupName]: {
            ...state.groups[groupName],
            items: groupItems,
          },
        },
      }));
    },
  }),
  {
    name: 'priority-renderer-store',
    partialize: (state) => ({
      groups: state.groups,
    }),
    storage: createJSONStorage(() => localStorage),
  },
  { name: 'priority-renderer-store', trace: true }
);

export const usePriorityRendererShallowStore = createShallowStore<PriorityRendererStore>(usePriorityRendererStore);
