import { useState } from 'react';
import { css } from '@emotion/react';
import { WeaveLogoNew } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { DownloadSoftwareModal } from '@frontend/onboarding-modules';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  Button,
  IconButton,
  PopoverMenu,
  PopoverMenuItem,
  Text,
  useModalControl,
  usePopoverMenu,
} from '@frontend/design-system';

type BannerType = 'download' | 'download-permanent';

interface DownloadBannerProps extends React.ComponentProps<'div'> {
  actionLabel: string;
  trackingId?: string;
  shouldShowBanner?: () => boolean;
  onCloseClick?: (shouldReappear: boolean) => void;
  children: React.ReactNode;
  innerRef?: React.LegacyRef<HTMLDivElement>;
  type?: BannerType;
}

const styles = {
  bannerWrapper: css`
    container-type: inline-size;
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.low};
    width: 100%;
  `,
  banner: css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing(3)};
    gap: ${theme.spacing(4)};
    border: 1px solid ${theme.colors.secondary.eggplant30};
    background: ${theme.colors.secondary.eggplant10};
  `,
  standardBanner: css`
    @container (max-width:  ${breakpoints.medium.max}px) {
      flex-direction: column;
      padding: ${theme.spacing(1.5, 2)};
      gap: ${theme.spacing(2)};
      justify-content: center;
    }
  `,
  permanentBanner: css`
    flex-direction: column;
    padding: ${theme.spacing(1.5, 2)};
    gap: ${theme.spacing(2)};
    justify-content: center;
    border-radius: ${theme.borderRadius.medium};
  `,
  logo: css`
    display: inline-block;
    min-width: 96px;
    width: 96px;
  `,
  text: css`
    max-width: 700px;
  `,
  buttonSection: (isPermanent: boolean) => [
    css`
      display: flex;
      gap: ${theme.spacing(3)};
      align-items: center;
      margin-left: ${isPermanent ? '0' : 'auto'};
      margin-right: ${isPermanent ? 'auto' : '0'};

      @container (max-width: ${breakpoints.medium.max}px) {
        margin: ${!isPermanent ? 'auto' : '0'};
      }
    `,
  ],
  downloadButton: css`
    height: 40px;
    border: 1px solid ${theme.colors.neutral90};
    white-space: nowrap;
    font-weight: ${theme.font.weight.bold};
    &:hover,
    &:focus {
      border: 1px solid ${theme.colors.neutral90};
    }
  `,
  closeButton: css`
    padding: 7px;
    @container (max-width: ${breakpoints.medium.max}px) {
      position: absolute;
      top: ${theme.spacing(0.5)};
      right: ${theme.spacing(0.5)};
    }
  `,
};

export const DownloadBanner = ({
  actionLabel,
  shouldShowBanner,
  onCloseClick,
  children,
  type = 'download',
  trackingId,
  innerRef,
  ...rest
}: DownloadBannerProps) => {
  const [show, setShow] = useState(() => shouldShowBanner?.() ?? true);

  if (!show) return null;

  const isPermanent = type === 'download-permanent';

  return (
    <div css={styles.bannerWrapper} ref={innerRef}>
      <div css={[styles.banner, isPermanent ? styles.permanentBanner : styles.standardBanner]} {...rest}>
        {!isPermanent && (
          <span css={styles.logo}>
            <WeaveLogoNew />
          </span>
        )}

        <Text size='medium' css={styles.text}>
          {children}
        </Text>

        <section css={styles.buttonSection(isPermanent)}>
          <DownloadBannerButton trackingId={trackingId} actionLabel={actionLabel} />
          {type === 'download' && onCloseClick && (
            <DownloadBannerCloseButton
              onClick={(shouldReappear) => {
                onCloseClick(shouldReappear);
                setShow(false);
              }}
            />
          )}
        </section>
      </div>
    </div>
  );
};

interface DownloadBannerButtonProps {
  trackingId?: string;
  actionLabel: string;
}

const DownloadBannerButton = ({ trackingId, actionLabel }: DownloadBannerButtonProps) => {
  const { triggerProps, modalProps } = useModalControl();

  return (
    <>
      <Button variant='secondary' {...triggerProps} trackingId={trackingId} css={styles.downloadButton}>
        {actionLabel}
      </Button>
      <DownloadSoftwareModal modalProps={modalProps} />
    </>
  );
};

const DownloadBannerCloseButton = ({ onClick }: { onClick: (shouldReappear: boolean) => void }) => {
  const { t } = useTranslation('dashboard');
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({ placement: 'top-start' });

  return (
    <>
      <IconButton {...getTriggerProps()} css={styles.closeButton} label={t('Close')} size='small'>
        <Icon name='x-small' />
      </IconButton>
      <PopoverMenu {...getMenuProps()}>
        <PopoverMenuItem {...getItemProps({ index: 0, onClick: () => onClick(true) })}>
          {t('Remind me in 3 days')}
        </PopoverMenuItem>
        <PopoverMenuItem {...getItemProps({ index: 1, onClick: () => onClick(false) })}>
          {t('Never show again')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};

DownloadBanner.displayName = 'DownloadBanner';
