import { FC, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FeatureNotificationQueries, FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, Text } from '@frontend/design-system';
import { default as OnlineSchedulingPromoIcon } from '../../../assets/online-scheduling-promo-icon.png';
import { useFeaturePromoNotification } from '../../../hooks/useFeaturePromoNotification';
import { useFeatureSubscriptionConfig } from '../../../hooks/useFeatureSubscriptionConfig';
import { useFeatureUpgradeDetailsModal } from '../../../hooks/useFeatureUpgradeDetailsModal';
import { FeatureSubscriptionContext } from '../../../providers/FeatureSubscriptionProvider';
import { getBundleTrialDays, getUpgradeBundleDisplayName } from '../../../self-upgrade.util';
import { PromoActions } from './PromoActions';

interface OnlineSchedulingPromoToastCardProps {
  locationIds: string[];
  source: FeatureNotificationTypes.FeatureNotificationSource;
  styles?: Interpolation<Theme>;
  getPromoContent?: (config: { isBundleUpgradeEligible: boolean; bundleName?: string; bundleTrialDays?: number }) => {
    title: string;
    subtitle: string;
    imagePath?: string;
  };
  onActionSuccess?: () => void;
  innerRef?: React.LegacyRef<HTMLDivElement>;
}

const FEATURE_ENUM = Feature.ONLINE_SCHEDULING;

export const OnlineSchedulingPromoToastCard: FC<OnlineSchedulingPromoToastCardProps> = ({
  locationIds,
  source,
  styles,
  getPromoContent,
  onActionSuccess,
  innerRef,
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'online-scheduling-promo' });

  const { mutate, isLoading } = FeatureNotificationQueries.useFeatureNotificationAction({
    onSuccess: onActionSuccess,
  });

  const featureSubscriptionConfig = useFeatureSubscriptionConfig({
    feature: FEATURE_ENUM,
    locationIds,
  });

  const { featureUpgradeDetailsModalControls, FeatureUpgradeDetailsModal } = useFeatureUpgradeDetailsModal({
    feature: FEATURE_ENUM,
    isBundleUpgradeEligible: featureSubscriptionConfig.isBundleUpgradeEligible,
  });

  const { showNotification, dismissActions, primaryAction } = useFeaturePromoNotification({
    featureEnum: FEATURE_ENUM,
    source,
    locationIds,
  });

  const handleDismissAction = (dismissAction: FeatureNotificationTypes.FeatureNotificationAction) => {
    mutate({
      actionEnum: dismissAction,
      sourceEnum: [source],
      featureEnum: FEATURE_ENUM,
    });
  };

  const handleUpgradeActionSuccess = () => {
    // Call dismiss action to hide the notification for 3 days on all source
    mutate({
      actionEnum: FeatureNotificationTypes.FeatureNotificationAction.ACTION_REMIND_AFTER_3_DAYS,
      sourceEnum: [
        FeatureNotificationTypes.FeatureNotificationSource.SOURCE_APPOINTMENT_TEASER_BANNER,
        FeatureNotificationTypes.FeatureNotificationSource.SOURCE_DASHBOARD_TEASER_BANNER,
        FeatureNotificationTypes.FeatureNotificationSource.SOURCE_MESSAGING_TEASER_BANNER,
      ],
      featureEnum: FEATURE_ENUM,
    });
  };

  const { title, subtitle, imagePath } = useMemo(() => {
    return (
      getPromoContent?.({
        isBundleUpgradeEligible: featureSubscriptionConfig.isBundleUpgradeEligible,
        bundleName: getUpgradeBundleDisplayName(featureSubscriptionConfig.recommendedBundle),
        bundleTrialDays: getBundleTrialDays(featureSubscriptionConfig.recommendedBundle),
      }) ?? {
        title: '',
        subtitle: '',
        imagePath: OnlineSchedulingPromoIcon,
      }
    );
  }, [featureSubscriptionConfig.isBundleUpgradeEligible, featureSubscriptionConfig.recommendedBundle]);

  return (
    <FeatureSubscriptionContext.Provider value={featureSubscriptionConfig}>
      {showNotification && (
        <div css={[cardStyles, styles]} ref={innerRef}>
          <ContentLoader show={isLoading} />
          <div>
            <img
              alt={t('Online scheduling promo icon')}
              css={promoIconStyles}
              src={imagePath || OnlineSchedulingPromoIcon}
              loading='lazy'
            />
          </div>
          <div css={{ display: 'flex', flex: 1, flexDirection: 'column', gap: theme.spacing(1) }}>
            <Text weight='bold'>{title || t('Get rid of unscheduled time on your calendar this week.')}</Text>
            <Text size='medium'> {subtitle || t('Let your patients book their own appointment online.')}</Text>
            <PromoActions
              source={source}
              disabled={isLoading}
              featureEnum={Feature.ONLINE_SCHEDULING}
              primaryAction={primaryAction}
              dismissActions={dismissActions}
              onDismissAction={handleDismissAction}
              onUpgradeAction={featureUpgradeDetailsModalControls.openModal}
            />
          </div>
        </div>
      )}
      {!!FeatureUpgradeDetailsModal && (
        <FeatureUpgradeDetailsModal
          modalControls={featureUpgradeDetailsModalControls}
          onActionSuccess={handleUpgradeActionSuccess}
        />
      )}
    </FeatureSubscriptionContext.Provider>
  );
};

const cardStyles = css({
  display: 'flex',
  padding: theme.spacing(2),
  overflow: 'hidden',
  backgroundColor: theme.colors.secondary.seaweed5,
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.secondary.seaweed50}`,
  gap: theme.spacing(1),
  maxWidth: 360,
});

const promoIconStyles = css({
  aspectRatio: 1,
  width: 48,
  flexShrink: 0,
});
