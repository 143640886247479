import { FC } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { useLocalStorage } from '@frontend/web-storage';
import { theme } from '@frontend/theme';
import { Button, NakedButton, Text } from '@frontend/design-system';
import { useDismissHandler } from '../../hooks/useDismissHandler';
import { CommonActionTrackingIds, getPaymentHVATrackingIds } from '../../tracking-ids';
import { PaymentsProviderWrapper } from '../common/PaymentsProviderWrapper';
import { ActionCards } from './ActionCards';
import { AppointmentsPanel } from './AppointmentsPanel';
import { DismissButton } from './DismissButton';
import { PaymentContentSlider } from './PaymentContentSlider';

interface PaymentsPromotionDashboardCardProps {
  styles: Interpolation<Theme>;
  onClose?: () => void;
  innerRef?: React.LegacyRef<HTMLDivElement>;
}

const PROMOTION_SOURCE = 'dashboard';
const PROMO_COLLAPSE_STATE_STORAGE_KEY = 'high-value-adoption:payments-dashboard:promo-collapse-state';

export const PaymentsPromotionDashboardCard: FC<PaymentsPromotionDashboardCardProps> = ({
  styles,
  innerRef,
  onClose,
}) => {
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });
  const [isCollapsed, setIsCollapsed] = useLocalStorage({
    key: PROMO_COLLAPSE_STATE_STORAGE_KEY,
  });

  const { isDismissed, handleDismiss } = useDismissHandler('payments-dashboard-hva-card');

  const { selectedLocationIds } = useAppScopeStore();
  const selectedLocationId = selectedLocationIds[0];

  // TODO: Implement logic for appointments when api is ready
  const appointments: Appointment[] = [];

  if (isDismissed) {
    return null;
  }

  return (
    <PaymentsProviderWrapper locationId={selectedLocationId} source={PROMOTION_SOURCE}>
      <div css={[containerStyles, styles]} ref={innerRef}>
        <div css={titleStyles}>
          <div css={textIconStyles}>
            <Icon name='bulb-small' css={mainIconStyles} />
            <Text size='large' weight='bold'>
              {t('Your plan includes Payments')}
            </Text>
          </div>
          {isCollapsed ? (
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <DismissButton
                source={PROMOTION_SOURCE}
                onDismiss={(snoozeDays) => {
                  handleDismiss(snoozeDays);
                  onClose?.();
                }}
              />
              <Button
                variant='tertiary'
                trackingId={getPaymentHVATrackingIds({
                  source: PROMOTION_SOURCE,
                  trackingId: CommonActionTrackingIds.EXPAND_CARD,
                })}
                onClick={() => setIsCollapsed(false)}
              >
                {t('Learn More')}
              </Button>
            </div>
          ) : (
            <NakedButton
              css={[collapseButtonStyles, { gap: theme.spacing(0.5) }]}
              trackingId={getPaymentHVATrackingIds({
                source: PROMOTION_SOURCE,
                trackingId: CommonActionTrackingIds.COLLAPSE_CARD,
              })}
              onClick={() => setIsCollapsed(true)}
            >
              <Icon name={'collapse-small'} css={{ color: theme.colors.neutral50 }} />
              <Text size='medium' weight='bold' css={{ color: theme.colors.neutral50 }}>
                {t('Collapse')}
              </Text>
            </NakedButton>
          )}
        </div>
        <div css={[bodyStyles, { height: isCollapsed ? 0 : 'auto' }]}>
          <PaymentContentSlider styles={contentSectionStyles} />
          {!!appointments?.length ? (
            <AppointmentsPanel appointments={appointments} styles={actionSectionStyles} />
          ) : (
            <ActionCards styles={actionSectionStyles} />
          )}
        </div>
      </div>
    </PaymentsProviderWrapper>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.borderRadius.large,
  backgroundColor: theme.colors.white,
});

const titleStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  gap: theme.spacing(1),
});

const textIconStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
});

const collapseButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  height: 32,
  flexShrink: 0,
});

const bodyStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  overflow: 'hidden',
});

const contentSectionStyles = css({
  flex: 1.5,
  minWidth: 400,
  [`@media (max-width: ${breakpoints.medium.max}px)`]: {
    flex: 1,
    minWidth: 300,
  },
});

const actionSectionStyles = css({
  flex: 1,
  minWidth: 300,
});

const mainIconStyles = css({
  borderRadius: theme.borderRadius.full,
  backgroundColor: theme.colors.tangerine10,
  color: theme.colors.tangerine50,
  padding: theme.spacing(0.5),
  boxSizing: 'content-box',
});
