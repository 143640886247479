import { css } from '@emotion/react';
import { Chip, Dot } from '@frontend/design-system';

interface Props {
  name: string;
  color: string;
}

export const AppointmentTypeChip = ({ name, color }: Props) => {
  return (
    <Chip
      key={name}
      variant='neutral'
      css={{ maxWidth: 100 }}
      leftElement={Boolean(color) && <Dot color='warning' css={[{ background: color }, dotStyle]} />}
    >
      {name}
    </Chip>
  );
};

const dotStyle = css({
  border: 'none',
  position: 'relative',
  minWidth: 8,
  width: 8,
  height: 8,
});
