import { cloneElement, ComponentProps, FC, isValidElement, ReactElement, useCallback, useEffect, useRef } from 'react';
import { isFunction } from 'lodash-es';
import { usePriorityRendererStore } from '../store/priority-renderer.store';

const NODE_HIDE_STYLES = 'display: none;';
interface PriorityRendererItemWrapperProps<T extends FC<any>> {
  itemName: string;
  groupName: string;
  closeFnProp: keyof ComponentProps<T>;
  children: ReactElement<{ innerRef?: React.LegacyRef<HTMLElement> }> | null | false;
}

/**
 * Priority renderer item wrapper will render the child only if the item is the top priority item in the group
 * @param itemName string - unique name of the item
 * @param groupName string - name of the group
 * @param closeFnProp string - name of the callback method prop that will be called when the item is closed
 * @param children ReactElement - child to be rendered. The child component should support an innerRef prop to detect if child is rendered
 *
 * @example
 * <PriorityRendererItemWrapper<typeof Component1> itemName="item1" groupName="group1" closeFnProp="onClose">
 *  <Component1 />
 * </PriorityRendererItemWrapper>
 *
 */
export const PriorityRendererItemWrapper = <T extends FC<any>>({
  children,
  closeFnProp,
  groupName,
  itemName,
}: PriorityRendererItemWrapperProps<T>) => {
  const { isGroupInitialized, isTopPriorityItem, registerItem, unregisterItem, markForDeprioritization } =
    usePriorityRendererStore();

  const nodeOriginalStyleRef = useRef<string | null>(null);

  useEffect(() => {
    return () => {
      unregisterItem?.({ groupName, itemName });
      nodeOriginalStyleRef.current = null;
    };
  }, []);

  const isVisible = isTopPriorityItem?.({ groupName, itemName });

  const handleNodeRefMount = useCallback(
    (node: HTMLElement) => {
      if (node) {
        registerItem?.({ groupName, itemName });
        const nodeStyles = node.getAttribute('style');
        if (nodeStyles !== NODE_HIDE_STYLES && nodeOriginalStyleRef.current !== nodeStyles) {
          nodeOriginalStyleRef.current = nodeStyles;
        }
        if (isVisible) {
          node.setAttribute('style', nodeOriginalStyleRef.current || '');
        } else if (!isVisible) {
          node.setAttribute('style', NODE_HIDE_STYLES);
        }
      }
    },
    [isVisible]
  );

  if (!isGroupInitialized?.(groupName)) {
    return null;
  }

  if (!isValidElement(children)) {
    return children;
  }

  return cloneElement(children, {
    innerRef: handleNodeRefMount,
    [closeFnProp]: (...args: any[]) => {
      // Mark item for deprioritization and call the close function if any
      markForDeprioritization?.({ groupName, itemName });
      closeFnProp in children.props &&
        isFunction(children.props[closeFnProp as keyof typeof children.props]) &&
        (children.props[closeFnProp as keyof typeof children.props] as any)(...args);
    },
  });
};
